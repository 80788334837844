import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BASE_URL,API_BASE_URL } from 'config';
import { ToastContainer, toast } from 'react-toastify';
import ScrollToTop from "views/common/ScrollToTop";
import ScrollToTopBtn from "views/common/ScrollToTopBtn";
import 'react-toastify/dist/ReactToastify.css';
import DefaultLayout from './views/DefaultLayout/DefaultLayout';
import CustomerLayout from './views/CustomerLayout/CustomerLayout';
/* import AdminLayout from './views/AdminLayout/AdminLayout'; */
import Login from './views/pages/Auth/Login';
/* import AdminLogin from './views/pages/Auth/AdminLogin'; */
import ForgotPassword from './views/pages/Auth/ForgotPassword';
import ResetPassword from './views/pages/Auth/ResetPassword';
import SoldConfirmation from './views/pages/Public/SoldConfirmation';
import Register from './views/pages/Auth/Register';
import {isEmpty} from 'config';
import 'App.css';
toast.configure({"className":"toast-notify","autoClose":2000});
class App extends Component {
	
	constructor(props) {
		super(props);
	}	
	
	startLoader = () => (<div><div className="body-overlay"></div><div className="spinner-border text-warning"><span className="sr-only">Loading...</span></div></div>);
	
    componentWillMount(){
        axios.defaults.baseURL = API_BASE_URL+'/api';
        let authToken = localStorage.getItem('auth');
        axios.defaults.headers.common['Accept-Version'] = "1";
        axios.defaults.headers.common['Access'] = "*";
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.common['Content-Type'] = "application/json; charset=utf-8";
        axios.defaults.headers.common['authorization'] = !isEmpty(authToken)?authToken:"";
        axios.defaults.validateStatus = function (status) {
			return status >= 200 && status < 500;
		};
    }	

	notify = (message="",type='') =>{
		switch (type) {
			case "success":
				toast.success(message,{"className":"bg-success msg-success"});
			break;
			case "warning":
				toast.warn(message,{"className":"bg-warning msg-warning"});
			break;
			default:
				toast.error(message,{"className":"bg-dnager msg-dnager"});
		}
	};
    
    render() {
      return (
			<Router>
				<div className="page-container">
					<ScrollToTop>
						<Switch>
							/* ----------------------  Customer Section --------------------------- */
							<Route exact path="/customer/login" name="customerLogin" render={(props) => <Login {...props} notify={ this.notify.bind(this)} title="Customer Login" type="customer"/>} />
							<Route exact path="/customer/forgot-password" name="customerForgotPassword" render={(props) => <ForgotPassword {...props} notify={ this.notify.bind(this)} title="Customer Forgot Password" type="customer" />} />
							<Route exact path="/customer/register" name="customerRegister" render={(props) => <Register {...props} notify={ this.notify.bind(this)} title="Customer Registration" type="customer" />} />
							<Route path="/customer" name="Customer" render={(props) => <CustomerLayout {...props} startLoader={ this.startLoader.bind(this)} notify={ this.notify.bind(this)} />} />
							
							/* ----------------------  Public Section --------------------------- */
							<Route exact path="/reset-password/:verificationToken" name="Reset Password" render={(props) => <ResetPassword {...props} notify={ this.notify.bind(this)} title="Reset Password" />} />
							<Route exact path="/sold-confirmation/:verificationToken" name="Sold Confirmation" render={(props) => <SoldConfirmation {...props} notify={ this.notify.bind(this)} title="Reset Password" />} />
							<Route path="/" name="Home" render={(props) => <DefaultLayout {...props} startLoader={ this.startLoader.bind(this)} notify={ this.notify.bind(this)}/>} />
						</Switch>
						<ScrollToTopBtn />
					</ScrollToTop>
				</div>
			</Router>
      );
    }
}
export default App;