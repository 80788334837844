import React, {Component} from 'react';
import {isEmpty} from 'config';
import { Alert } from 'react-bootstrap';

export default class FlashMessages extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
			type: (!isEmpty(props.type)?props.type:"error"),
			message: (!isEmpty(props.message)?props.message:""),
			alertShow: true,
		}
		this.state 					= this.initialState;
    }
    
	alertDismiss = ()=>{
		this.setState(prevState => ({
		  alertShow: !prevState.alertShow
		}));
	}
	
    render() {
		const state				= this.state;
		let variant			= "danger";
		if( state.type=="success" ){
			variant			= "success";
		}
		if( state.type=="warning" ){
			variant			= "warning";
		}
        return (
			(state.alertShow)?(
			<Alert variant={variant} onClose={() => this.alertDismiss()} dismissible>
				{state.message}
			</Alert>
			):(null)
        );
    }
}