import React, {Component} from 'react';
import CommonFooter from 'views/common/CommonFooter';

export default class Footer extends Component {
    
    constructor(){
        super();
        this.state = {
            count : 0
        }
    }
    render() {
        return (
				<CommonFooter />
                );
    }
}