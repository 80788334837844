import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button } from 'react-bootstrap';
import Header from 'views/DefaultLayout/Header/Header';
import Footer from 'views/DefaultLayout/Footer/Footer';
import {publicRoutes} from 'routes';
import axios from 'axios';
import {isEmpty} from 'config';
import HeaderMeta from 'views/common/HeaderMeta';

export default class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfoID: "",
            confirmPassword: "",
            newPassword: "",
        };
		this.validator 				= new SimpleReactValidator({autoForceUpdate: this});
		this.handleChange 	= this.handleChange.bind(this);
		this.handleSubmit 	= this.handleSubmit.bind(this);
    }
 
	handleChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value
		})
	}
 
	componentDidMount(){
		const  params 			= this.props.match.params;
		let verificationCode	= params.verificationToken;
        axios({
            url:"/checkVerificationToken",
            method:"POST",
            data:{"verificationCode":verificationCode},
        }).then((result) => {
            let resultData = result.data;
            if(resultData.status){
				resultData = resultData.data;
				this.setState({
					"userInfoID": resultData.userInfoID,
				});
            }else{
				this.props.notify(resultData.message);
				this.props.history.push({"pathname":'/customer/login'});
			}
        }).catch((error) => {
            console.log(error);
        })
    }

	handleSubmit(event) {
		event.preventDefault();
		if (this.validator.allValid()) {
			let data = {
				"userInfoID": this.state.userInfoID,
				"confirmPassword": this.state.confirmPassword,
				"newPassword": this.state.newPassword,
			}
			axios({
                method: 'post',
                url: "resetPasswordUpdate",
                data: data
            }).then((result) => {
                let resultObj = result.data;
                if (resultObj.status) {
					this.props.notify(resultObj.message,"success");					
                }else{
					this.props.notify(resultObj.message);
                }
				this.props.history.push({"pathname":'/customer/login'});
            }).catch((error) => {
                this.props.notify("Network Error!");
            })
		} else {
			this.validator.showMessages();
		}
	}
    
    render() {
        return (
			<div className="page-content-wrapper">
				<HeaderMeta title="Reset Password"/>
				<Header Content={publicRoutes} {...this.props} />
				<section className="full-section signup-section form-section">
					<div className="container bg-white">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 pr-lg-0">
								<div className="login-block new-customer">
									<span className="block-title h3"> Reset Password:</span>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-sm-8 col-md-7 col-lg-7">
								<Form onSubmit={this.handleSubmit}>
									<Form.Group className="input-field required" controlId="newPassword">
										<Form.Label className="control-label">New Password:</Form.Label>
										<Form.Control
										  type="password"
										  name="newPassword"
										  value={this.state.newPassword}
										  onChange={this.handleChange}/>
										  {this.validator.message('newPassword', this.state.newPassword, 'required|min:6',{'className':'text-danger'})}
									</Form.Group>
									<Form.Group className="input-field required" controlId="confirmPassword">
										<Form.Label className="control-label">Confirm Password:</Form.Label>
										<Form.Control
										  type="password"
										  name="confirmPassword"
										  value={this.state.confirmPassword}
										  onChange={this.handleChange}/>
										  {this.validator.message('confirmPassword', this.state.confirmPassword, `required|min:6|in:${this.state.newPassword}`, {messages: {in: 'Password and confirm password does not match'},'className':'text-danger'})}
									</Form.Group>
									<div className="row mb-3">										
										<div className="col-6">
											<div className="">
												<Button type="submit" className="text-uppercase btn btn-primary btn-theme">Submit</Button>
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</section>
				<Footer {...this.props} />
			</div>
        );
    }
}