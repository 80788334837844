import React, {Component} from 'react';
import {isEmpty} from 'config';
import {Helmet} from "react-helmet";

export default class HeaderMeta extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
			title: (!isEmpty(props.title)?props.title:""),
			onltyTitle: (!isEmpty(props.onltyTitle)?props.onltyTitle:false)
		}
		this.state 					= this.initialState;
    }
    
    render() {
        return (
            <div className="application">
				<Helmet>
                    <meta charSet="utf-8" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="#333333" />
					<title>{(this.state.onltyTitle)?this.state.title:this.state.title+" - WAM"} </title>
				</Helmet>
			</div>
        );
    }
}