import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button } from 'react-bootstrap';
import Header from 'views/DefaultLayout/Header/Header';
import Footer from 'views/DefaultLayout/Footer/Footer';
import {publicRoutes} from 'routes';
import axios from 'axios';
import InputMask from 'react-input-mask';

import {isEmpty} from 'config';
import HeaderMeta from 'views/common/HeaderMeta';
import FlashMessages from 'views/common/FlashMessages';

export default class Register extends Component {
	
	constructor(props) {
		super(props);
		this.initialState = {
			pageTitle: (!isEmpty(props.title)?props.title:""),
			routeType: (!isEmpty(props.type)?props.type:""),
			userType: (props.type=='customer'?"1":"2"),
			flashMessage: "",
			messageType: "error",
			password: '',
			email: '',
			firstName: '',
			lastName: '',
			street: '',
			city: '',
			state: '',
			zip: '',
			phone: '',
		}
		this.state 					= this.initialState;
		this.validator 				= new SimpleReactValidator({autoForceUpdate: this});
		this.handleChange 	= this.handleChange.bind(this);
		this.handleSubmit 	= this.handleSubmit.bind(this);
		if(!isEmpty(localStorage.getItem('auth'))){
            this.props.history.push('/'+props.type);
        }
	}

	handleChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value
		})
	}
	
	handleSubmit(event) {
		event.preventDefault();
		if (this.validator.allValid()) {
			let data = {
				"pageTitle": this.state.pageTitle,
				"routeType": this.state.routeType,
				"password": this.state.password,
				"email": this.state.email,
				"firstName": this.state.firstName,
				"lastName": this.state.lastName,
				"street": this.state.street,
				"city": this.state.city,
				"state": this.state.state,
				"zipcode": this.state.zip,
				"phone": this.state.phone,
				"userType": this.state.userType,
				"deviceType": '3', 
				"deviceToken": 'f4d65f46d54f6sd54f'
			}
			axios({
                method: 'post',
                url: "customerRegistration",
                data: data
            }).then((result) => {
                let resultObj = result.data;
                if (resultObj.status) {
                    localStorage.removeItem('auth');
                    localStorage.setItem('auth', resultObj.data.token);
					axios.defaults.headers.common['authorization'] = resultObj.data.token;
					this.props.history.push({ "pathname":'/list-your-car'});
					//this.props.notify(resultObj.message,"success");
                }else{
					this.setState({
						"flashMessage": resultObj.message
					});
					//this.props.notify(resultObj.message);
                }
            }).catch((error) => {
				this.setState({
					"flashMessage":"Network Error!"
				});
            })
		} else {
			this.validator.showMessages();
		}
	}

	render() {
		return (
			<div className="page-content-wrapper">
				<HeaderMeta title="Customer Registration"/>
				<Header Content={publicRoutes} {...this.props} />
				<section className="full-section signup-section form-section">
					<div className="container bg-white">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 pr-lg-0">
								<div className="login-block new-customer">
									<span className="block-title h3"> Please fill out your information:</span>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12">
								<div className="card-form card-login card-register">
									<Form onSubmit={this.handleSubmit}>
										{(this.state.flashMessage)?(<FlashMessages type={this.state.messageType} message={this.state.flashMessage} />):("")}
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field required" controlId="email">
													<Form.Label className="control-label">Email address:</Form.Label>
													<Form.Control
													  type="text"
													  name="email"
													  autoFocus={true}
													  value={this.state.email}
													  onChange={this.handleChange}/>
													  {this.validator.message('email', this.state.email, 'required|email',{'className':'text-danger'})}		 
												</Form.Group>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field required" controlId="password">
													<Form.Label className="control-label">Password: </Form.Label>
													<Form.Control
													  type="password"
													  name="password"
													  value={this.state.password}
													  onChange={this.handleChange}/>
													  {this.validator.message('password', this.state.password, 'required|min:6',{'className':'text-danger'})}		 
												</Form.Group>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field required" controlId="firstName">
													<Form.Label className="control-label">First Name: </Form.Label>
													<Form.Control
													  type="text"
													  name="firstName"
													  value={this.state.firstName}
													  onChange={this.handleChange}/>
													  {this.validator.message('firstName', this.state.firstName, 'required',{'className':'text-danger'})}	 
												</Form.Group>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field required" controlId="lastName">
													<Form.Label className="control-label">Last Name: </Form.Label>
													<Form.Control
													  type="text"
													  name="lastName"
													  value={this.state.lastName}
													  onChange={this.handleChange}/>
													  {this.validator.message('lastName', this.state.lastName, 'required',{'className':'text-danger'})}		 
												</Form.Group>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field" controlId="street">
													<Form.Label className="control-label">Street: </Form.Label>
													<Form.Control
													  type="text"
													  name="street"
													  value={this.state.street}
													  onChange={this.handleChange}/>
												</Form.Group>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field" controlId="city">
													<Form.Label className="control-label">City: </Form.Label>
													<Form.Control
													  type="text"
													  name="city"
													  value={this.state.city}
													  onChange={this.handleChange}/>
												</Form.Group>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field" controlId="state">
													<Form.Label className="control-label">State: </Form.Label>
													<Form.Control
													  type="text"
													  name="state"
													  value={this.state.state}
													  onChange={this.handleChange}/>
												</Form.Group>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field" controlId="zip">
													<Form.Label className="control-label">Zip Code: </Form.Label>
													<InputMask
													  mask="99999"
													  maskChar={null}
													  className="form-control"
													  name="zip"
													  value={this.state.zip}
													  onChange={this.handleChange}/>
												</Form.Group>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<Form.Group className="input-field required" controlId="phone">
													<Form.Label className="control-label">Phone Number</Form.Label>
													<InputMask
													  mask="999-999-9999"
													  //mask="9999999999"
													  name="phone"
													  maskChar={null}
													  className="form-control"
													  value={this.state.phone}
													  onChange={this.handleChange}/>
													  {this.validator.message('phone', this.state.phone, 'required|min:10',{messages:{min: 'Please enter phone number. Min. 10 digits'},'className':'text-danger'})}		 
												</Form.Group>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="form-group agreement">
													<label className="form-check-label">
														<span>By clicking Create account, I agree that I have read and agree to the <Link to={"/terms-and-conditions"}>Terms of Service </Link> and <Link to={"/privacy-policy"}>Privacy Policy</Link>.
														</span>												
													</label>
												</div>
											</div>
										</div>
										<div className="button-set form-button form-group">
											<Button className="btn btn-theme btn-register btn-primary text-uppercase" type="submit"><i className="fa fa-user"></i> <span> Create Account </span></Button>
										</div>
										<div className="loginhint">
											<span>Already a member? <Link to="/customer/login" className="btn-link">Log in</Link></span>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer {...this.props} />
			</div>
		);
	}
}