import React, { Component, Suspense } from 'react';
import {Link} from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import {publicRoutes} from 'routes';
import {isEmpty} from 'config';
import NoMatchPage from 'views/pages/NoMatchPage';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Switch } from 'react-router-dom';
import './DefaultLayout.css';
toast.configure({"className":"toast-notify"});

export default class DefaultLayout extends Component {
	
    constructor(props) {
        super(props);
    }

	notify = (message="",type='') =>{
			switch (type) {
				case "success":
					toast.success(message,{"className":"bg-success msg-success"});
				break;
				case "warning":
					toast.warn(message,{"className":"bg-warning msg-warning"});
				break;
				default:
					toast.error(message,{"className":"bg-dnager msg-dnager"});
			}
		};
	
    render() {
        return(
            <div className="page-content-wrapper">
				 <Header Content={publicRoutes} {...this.props} />
				<Suspense fallback={this.props.startLoader()}>
					<Switch>
						{publicRoutes.map((route, idx) => {
							return (typeof route.component !== "undefined" ? (
								<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component notify={ this.notify.bind(this) } {...props} />)} />
								) : (null))
						} ) }
						<Route component={NoMatchPage} />
					</Switch>
				</Suspense>
				<Footer Content={publicRoutes} {...this.props} />
            </div>
        );
    }
}