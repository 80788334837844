import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button } from 'react-bootstrap';
import Header from 'views/DefaultLayout/Header/Header';
import Footer from 'views/DefaultLayout/Footer/Footer';
import {publicRoutes} from 'routes';
import axios from 'axios';
import {isEmpty} from 'config';
import HeaderMeta from 'views/common/HeaderMeta';

export default class Login extends Component {
	
	constructor(props) {
		super(props);
		this.initialState = {
			pageTitle: (!isEmpty(props.title)?props.title:""),
			routeType: (!isEmpty(props.type)?props.type:""),
			userType: (props.type=='customer'?"1":"2"),
			password: '',
			email: '',
		}
		this.state 					= this.initialState;
		this.validator 				= new SimpleReactValidator({autoForceUpdate: this});
		this.handleChange 	= this.handleChange.bind(this);
		this.handleSubmit 	= this.handleSubmit.bind(this);
		if(!isEmpty(localStorage.getItem('auth'))){
            this.props.history.push('/'+props.type);
        }
	}

	handleChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value
		})
	}
	
	handleSubmit(event) {
		event.preventDefault();
		if (this.validator.allValid()) {
			let data = {
				"email": this.state.email,
				"password": this.state.password,
				"userType": this.state.userType,
				"deviceType": "3",
				"deviceToken": "f4d65f46d54f6sd54f"
			};
			axios({
                method: 'post',
                url: "customerLogin",
                data: data
            }).then((result) => {
                let resultObj = result.data;
                if (resultObj.status) {
                    localStorage.removeItem('auth');
                    localStorage.setItem('auth', resultObj.data.token);
					//this.props.notify(resultObj.message,"success");
					axios.defaults.headers.common['authorization'] = resultObj.data.token;
					if (!isEmpty(localStorage.getItem('preURL'))) {
						this.props.history.push(localStorage.getItem('preURL'));
						localStorage.removeItem('preURL');
					} else {
						if (!isEmpty(localStorage.getItem('selectedPackage'))) {
							this.props.history.push('/customer/add-vehicle-info');
						} else {
							this.props.history.push({ "pathname": '/customer/profile', state: { "loginUser": resultObj.data.userInfo } });
						}
					}
                }else{
					this.props.notify(resultObj.message);
                }
            }).catch((error) => {
                this.props.notify("Network Error!");
            })
		} else {
			this.validator.showMessages();
		}
	}

	render() {
		return (
			<div className="page-content-wrapper">
				<HeaderMeta title="Customer Login"/>
				<Header Content={publicRoutes} {...this.props} />
				<section className="full-section login-section form-section">
					<div className="container bg-white">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 new-customer-col">
								<div className="login-block new-customer">
									<span className="block-title h3"> New Customers</span>
									<p>Get the most money for your car when you sell privately. Simply create an account and list your vehicle for sale. </p>
									<div className="button-set">
										<Link to="/customer/register" className="btn btn-theme text-uppercase btn-register btn-primary"><span><i className="fa fa-user"></i> Create Account </span></Link>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 registered-custome-col">
								<div className="login-block registered-customer">
									<span className="block-title h3"> Registered Customers</span>
									<p>If you have an account with us, log in here.</p>
									<div className="card-form card-login">
										<Form onSubmit={this.handleSubmit}>
											<Form.Group className="input-field" controlId="email">
												<Form.Label className="control-label">Email address: </Form.Label>
												<Form.Control
												  type="text"
												  name="email"
												  value={this.state.email}
												  autoFocus={true}
												  onChange={this.handleChange}/>
												  {this.validator.message('email', this.state.email, 'required|email',{'className':'text-danger'})}		 
											</Form.Group>
											<Form.Group className="input-field" controlId="password">
												<Form.Label className="control-label">Password: </Form.Label>
												<Form.Control
												  type="password"
												  name="password"
												  value={this.state.password}
												  onChange={this.handleChange}/>
												  {this.validator.message('password', this.state.password, 'required|min:6',{'className':'text-danger'})}		 
											</Form.Group>
											<div className="form-group rememberme">
												<label className="checkbox_container">Remember me
												  <input type="checkbox" name="rememberme" />
												  <span className="checkmarkcss"></span>
												</label>
											</div>
											<div className="button-set form-button">
												<Button className="btn btn-theme btn-login btn-primary text-uppercase" type="submit"><i className="fa fa-unlock-alt"></i> <span> Login </span></Button>
												<span className="forgotpswd">
													<Link to="/customer/forgot-password" className="btn btn-link text-black text-dark">Forgot Your Password?</Link>
												</span>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer {...this.props} />
			</div>
		);
	}
}