import moment from 'moment';
var hasOwnProperty = Object.prototype.hasOwnProperty;

export const BASE_URL=process.env.REACT_APP_BASE_URL || 'http://localhost:3001';
export const API_BASE_URL=process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
export const UPLOAD_URL=process.env.REACT_APP_UPLOAD_URL || 'http://localhost:3000/uploads/';

export const timeZone=process.env.REACT_APP_TIME_ZONE || 'UTC';
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || 'pk_live_iJm1CSUF62cdTWJ6PyfvDDSB00EiJOMAyX';

export const TWITTER_URL=process.env.REACT_APP_TWITTER_URL || ""
export const FACEBOOK_URL=process.env.REACT_APP_FACEBOOK_URL || ""
export const INSTAGRAM_URL=process.env.REACT_APP_INSTAGRAM_URL || ""

export function getAdminStyle() {
	return [{
					"id" : "adminFa",
					"href" : "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
				},{
					"id" : "adminStyle",
					"href" : "/assets/css/admin/admin-styles.css"
				},{
				"id" : "customStyle",
				"href" : "/assets/css/admin/custom-style.css"
				},{
				"id" : "responsive",
				"href" : "/assets/css/admin/responsive.css"
				},{
				"id" : "webfonts",
				"href" : "/assets/css/admin/webfonts.css"
				}
	];
}

export function isEmpty(obj) {
    if (obj == "") return true;
    if (obj === 0) return true;
    if (obj === "0") return true;
    if (obj == null) return true;
    if (obj === false) return true;
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;
    if (typeof(obj)=="undefined")  return true;
    //if (typeof obj !== "object") return true;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }
}

export function isEmptyObj(obj) {
	for (var prop in obj) {
		if(obj.hasOwnProperty(prop)) {
			return false;
			break;
		}
	}
	return true;
}

export function formatDate(date) {
  var day = date.getDate();
  var month = date.getMonth()+ 1;
  var year = date.getFullYear();

  month = (month.toString().length == "1" ? "0"+month : month)  
  day = (day.toString().length == "1" ? "0"+day : day)  

  return year + '-' + month + '-' + day;
}

export function getFormattedDate(date,format="") {
	if(!isEmpty(date)){		
		var date 	= new Date(moment(date).format());
		var day 	= date.getDate();
		var month = date.getMonth()+ 1;
		var year = date.getFullYear();

		month = (month.toString().length == "1" ? "0"+month : month)  
		day = (day.toString().length == "1" ? "0"+day : day)  
		return month + '-' + day + '-' + year;
	}
}

export const numberFormat = (value,currency=false) =>{
  var options  = {};
  if( currency ){
	  options	=  {
		style: 'currency',
		currency: 'USD'
	  };
	}
	return new Intl.NumberFormat('en-US',options).format(value);
}