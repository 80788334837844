import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button } from 'react-bootstrap';
import Header from 'views/DefaultLayout/Header/Header';
import Footer from 'views/DefaultLayout/Footer/Footer';
import {publicRoutes} from 'routes';
import axios from 'axios';
import {isEmpty} from 'config';
import HeaderMeta from 'views/common/HeaderMeta';

export default class ForgotPassword extends Component {
	
	constructor(props) {
		super(props);
		this.initialState = {
			pageTitle: (!isEmpty(props.title)?props.title:""),
			routeType: (!isEmpty(props.type)?props.type:""),
			userType: (props.type=='customer'?"1":"2"),
			email: '',
		}
		this.state 					= this.initialState;
		this.validator 				= new SimpleReactValidator({autoForceUpdate: this});
		this.handleChange 	= this.handleChange.bind(this);
		this.handleSubmit 	= this.handleSubmit.bind(this);
		if(!isEmpty(localStorage.getItem('auth'))){
            this.props.history.push('/'+props.type);
        }
	}

	handleChange(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({
			[name]: value
		})
	}
	
	handleSubmit(event) {
		event.preventDefault();
		if (this.validator.allValid()) {
			let data = {
				"email": this.state.email,
				"userType": this.state.userType,
			};
			axios({
                method: 'post',
                url: "forgotPassword",
                data: data
            }).then((result) => {
                let resultObj = result.data;
                if (resultObj.status) {
					this.props.notify(resultObj.message,"success");
                    this.props.history.push({"pathname":'/customer/login'});
                }else{
					this.props.notify(resultObj.message);
                }
            }).catch((error) => {
                this.props.notify("Network Error!");
            })
		} else {
			this.validator.showMessages();
			console.log(this.state);
		}
	}

	render() {
		return (
			<div className="page-content-wrapper">
				<HeaderMeta title="Customer Forgot Password"/>
				<Header Content={publicRoutes} {...this.props} />
				<section className="full-section signup-section form-section">
					<div className="container bg-white">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 pr-lg-0">
								<div className="login-block new-customer">
									<span className="block-title h3"> Forgot Password:</span>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-sm-8 col-md-7 col-lg-7">
								<div className="card-form card-login card-register">
									<Form onSubmit={this.handleSubmit}>
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<Form.Group className="input-field required" controlId="email">
													<Form.Label className="control-label">Email Address: </Form.Label>
													<Form.Control
													  type="text"
													  name="email"
													  value={this.state.email}
													  autoFocus={true}
													  onChange={this.handleChange}/>
													  {this.validator.message('email', this.state.email, 'required|email',{'className':'text-danger'})}		 
												</Form.Group>
											</div>
										</div>
										<div className="button-set form-button">
											<Button className="btn btn-theme btn-register btn-primary text-uppercase" type="submit">Submit</Button>
											<span className="forgotpswd">
												<Link to="/customer/login" className="btn btn-link text-black text-dark"> Back to log in?</Link>
											</span>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer {...this.props} />
			</div>
		);
	}
}