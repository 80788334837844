import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button } from 'react-bootstrap';
import Header from 'views/DefaultLayout/Header/Header';
import Footer from 'views/DefaultLayout/Footer/Footer';
import {publicRoutes} from 'routes';
import axios from 'axios';
import {isEmpty} from 'config';
import HeaderMeta from 'views/common/HeaderMeta';
import FlashMessages from 'views/common/FlashMessages';

export default class SoldConfirmation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfoID: "",
            confirmPassword: "",
            newPassword: "",
			"flashMessage": "",
			"messageType": "error",
        };
    }
 
	componentWillMount(){
		const  params 			= this.props.match.params;
		let verificationCode	= params.verificationToken;
        axios({
            url:"/vehicleSoldRequest",
            method:"POST",
            data:{"verificationCode":verificationCode},
        }).then((result) => {
            let resultData = result.data;
            if(resultData.status){
				this.setState({
					flashMessage: resultData.message,
					messageType: "success",
				})
            }else{
				this.props.notify(resultData.message);
				this.props.history.push({"pathname":'/customer/login'});
			}
        }).catch((error) => {
            console.log(error);
        })
    }
    
    render() {
        return (
			<div className="page-content-wrapper">
				<HeaderMeta title="Vehicle Sold Confirmation"/>
				<Header Content={publicRoutes} {...this.props} />
				<section className="full-section signup-section form-section">
					<div className="container bg-white">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6 pr-lg-0">
								<div className="login-block new-customer">
									<span className="block-title h3">Vehicle Sold Confirmation</span>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								 {(this.state.flashMessage)?(<FlashMessages type={this.state.messageType} message={this.state.flashMessage} />):("")}
								 <Link className="text-uppercase btn btn-primary btn-theme" to="/">Go Back to Home</Link>
							</div>
						</div>
					</div>
				</section>
				<Footer {...this.props} />
			</div>
        );
    }
}