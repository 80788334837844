import React from 'react';

/*============================== Customer Section ==============================*/
const customerAddVehicleInfo = React.lazy((props) => import('./views/pages/Customer/vehicle/addInfo'));
//const customerAddVehicleImage = React.lazy((props) => import('./views/pages/Customer/vehicle/addImages'));
const customerChooseLocation = React.lazy((props) => import('./views/pages/Customer/vehicle/chooseLocation'));
const customerVehiclePayment = React.lazy((props) => import('./views/pages/Customer/vehicle/vehiclePayment'));
const customerPaymentSuccess = React.lazy((props) => import('./views/pages/Customer/vehicle/paymentSuccess'));
const customerProfile = React.lazy((props) => import('./views/pages/Customer/Profile'));
const customerProfileUpdate = React.lazy((props) => import('./views/pages/Customer/ProfileUpdate'));
const customerChangePassword = React.lazy((props) => import('./views/pages/Customer/ChangePassword'));
const customerVehicleList = React.lazy((props) => import('./views/pages/Customer/VehicleList'));
const customerVehicleView = React.lazy((props) => import('./views/pages/Customer/VehicleView'));
const customerEditVehicleInfo = React.lazy((props) => import('./views/pages/Customer/vehicle/editInfo'));
const customerUpdateVehicleImage = React.lazy((props) => import('./views/pages/Customer/vehicle/updateImages'));
const customerUpdateLocation = React.lazy((props) => import('./views/pages/Customer/vehicle/updateLocation'));
const vehicleReListPayment = React.lazy((props) => import('./views/pages/Customer/vehicle/vehicleReListPayment'));
/*============================== Customer Section ==============================*/

/*============================== Public Section =================================*/
const Home = React.lazy((props) => import('./views/pages/Public/Home'));
const ListYourCar = React.lazy((props) => import('./views/pages/Public/ListYourCar'));
const Search = React.lazy((props) => import('./views/pages/Public/Search'));
const VehicleDetails = React.lazy((props) => import('./views/pages/Public/VehicleDetails'));
const Faq = React.lazy((props) => import('./views/pages/Public/Faq'));
const AboutUs = React.lazy((props) => import('./views/pages/Public/AboutUs'));
const ContactUs = React.lazy((props) => import('./views/pages/Public/ContactUs'));
const PrivatePolicy = React.lazy((props) => import('./views/pages/Public/PrivatePolicy'));
const TermsAndConditions = React.lazy((props) => import('./views/pages/Public/TermsAndConditions'));
/*============================== Public Section =================================*/

/*============================== Public Routes =================================*/
export const publicRoutes = [
	{ path: "/", display:true, exact:true, name:"Home", component:Home, className: "" },
	{ path: "/list-your-car", display:true, name:"List Your Car", component:ListYourCar, className: "" },
	{ path: "/search/:locationID?/:carTypeID?", display:false, name:"Search", component:Search, className: "" },
	{ path: "/search", display:true, name:"Search", component:Search, className: "" },
	{ path: "/vehicle-details/:vehicleInfoID/:verificationToken?", display:false, name:"Vehicle Details", component:VehicleDetails, className: "" },
	{ path: "/faq", display:true, name:"Faq", component:Faq, className: "" },
	{ path: "/about-us", display:false, name:"About Us", component:AboutUs, className: "" },
	{ path: "/contact-us", display:false, name:"Contact Us", component:ContactUs, className: "" },
	{ path: "/privacy-policy", display:false, name:"Private Policy", component:PrivatePolicy, className: "" },
	{ path: "/terms-and-conditions", display:false, name:"Terms and Conditions", component:TermsAndConditions, className: "" },
];

/*============================== Customer Routes =================================*/
export const customerRoutes = [
	{ path: "/", display:true, exact:true, name:"Home", component:Home, className: "" },
	{ path: "/list-your-car", display:true, name:"List Your Car", component:ListYourCar, className: "" },
	{ path: "/customer/add-vehicle-info", display:false, name:"List Your Car", component:customerAddVehicleInfo, className: "" },
	{ path: "/search/:locationID?/:carTypeID?", display:false, name:"Search", component:Search, className: "" },
	{ path: "/search", display:true, name:"Search", component:Search, className: "" },
	{ path: "/faq", display:true, name:"Faq", component:Faq, className: "" },
	{ path: "/customer/profile", display:true, name:"My Account", component:customerProfile, className: "" },
	//{ path: "/customer/add-vehicle-images", display:false, name:"List Your Car", component:customerAddVehicleImage, className: "" },
	{ path: "/customer/choose-location/:vehicleInfoID", display:false, name:"Choose Location", component:customerChooseLocation, className: "" },
	{ path: "/customer/vehicle-payment/:vehicleInfoID/:couponCode?", display:false, name:"Choose Location", component:customerVehiclePayment, className: "" },
	{ path: "/customer/payment-success/:vehicleInfoID", display:false, name:"Choose Location", component:customerPaymentSuccess, className: "" },
	{ path: "/customer/profile-update", display:false, name:"Update profile", component:customerProfileUpdate, className: "" },
	{ path: "/customer/change-password", display:false, name:"Change Password", component:customerChangePassword, className: "" },
	{ path: "/customer/vehicle-list", display:false, name:"Vehicle List", component:customerVehicleList, className: "" },
	{ path: "/customer/vehicle/view/:vehicleInfoID", display:false, name:"Vehicle Details", component:customerVehicleView, className: "" },
	{ path: "/customer/edit-vehicle-info/:vehicleInfoID", display:false, name:"Update Vehicle", component:customerEditVehicleInfo, className: "" },
	{ path: "/customer/update-vehicle-images/:vehicleInfoID", display:false, name:"Update Image", component:customerUpdateVehicleImage, className: "" },
	{ path: "/customer/update-location/:vehicleInfoID/:token?", display:false, name:"Update Image", component:customerUpdateLocation, className: "" },
	{ path: "/customer/re-payment/:vehicleInfoID/:token?", display:false, name:"Update Image", component:vehicleReListPayment, className: "" },
];