import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import HeaderMeta from 'views/common/HeaderMeta';

export default class NoMatchPage extends Component {
    
    constructor(props) {
        super(props)
    }
	
	componentDidMount () {
		const handle = this.props.match.params;
	}
    
    render() {
        return (
             <div className="main-content">
				<HeaderMeta title="404"/>
				<div className="col-md-12 text-center">
					<span className="display-1 d-block">404</span>
					<div className="mb-4 lead">The page you are looking for was not found.</div>
					<Link to={"/"} className="btn btn-link">Back to Home</Link>
				</div>
			</div>
        );
    }
}