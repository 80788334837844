import React, {Component} from 'react';
import { Link} from 'react-router-dom';
import {TWITTER_URL,FACEBOOK_URL,INSTAGRAM_URL} from 'config';

export default class CommonFooter extends Component {
    
    constructor(){
        super();
    }
	
    render() {
        return (
				<footer className="footer-wrapper footer-lg">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-5 col-xl-6">
							</div>
							<div className="col-12 col-lg-7 col-xl-6">
								<div className="row">
									<div className="col-12 col-sm-6  col-md-6 col-lg-6 footer-grid">
										<div className="footer-widgt">
											<h4 className="footer-hading btn-block text-uppercase"> Information </h4>
											<ul className="footer-links list-unstyled">
												<li> <Link to="/about-us">About Us</Link></li>
											    <li> <Link to="/faq">FAQ</Link></li>
											    <li> <Link to="/contact-us">Contact Us</Link></li>
											    <li> <Link to="/privacy-policy">Privacy Policy</Link></li>
											    <li> <Link to="/terms-and-conditions">Terms Of Service</Link></li>
											</ul>
										</div>
									</div>
									<div className="col-12  col-sm-6  col-md-6 col-lg-6 footer-grid">
										<div className="footer-widgt">
											<div className="footer-address">
												<h4 className="footer-hading btn-block text-uppercase"> Company </h4>
												<p> Weekly Auto Market By HISTORIDE Inc.</p>
											</div>
											<div className="footer-contacts">
											<p><a href="mailto:info@WeeklyAutoMarket.com"> info@WeeklyAutoMarket.com  </a> </p>
											</div>
											<div className="social">
												<ul className="social-share list-unstyled">
													<li className="facebook"><a target="_blank" href={FACEBOOK_URL}><i className="fab fa-facebook-f"></i></a></li>
													<li className="twitter"><a target="_blank" href={TWITTER_URL}><i className="fab fa-twitter"></i></a></li>
													<li className="instagram"><a target="_blank" href={INSTAGRAM_URL}><i className="fab fa-instagram"></i></a></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="clearfix"></div>
								</div>
							</div>
						</div>
					</div>
					<section className="page-bottom">
						<div className="container">
							<div className="row bottom-row">
								<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<div className="bottom-text copyright">
										<ul className="list-unstyled list-inline">
											<li className="list-inline-item">
												<span><img src="/assets/images/wam-W.png" width="90" alt="Weekly Auto Market"/></span>
											</li>
											<li className="list-inline-item d-inline-flex align-items-end align-bottom">
											<span className="text-white"> &copy; {(new Date().getFullYear())} Weekly Auto Market by HISTORIDE Inc. All Rights Reserved</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="clearfix"></div>
							</div>
						</div>
					</section>
				</footer>
                );
    }
}