import React, {Component} from 'react';
import { NavLink, Link} from 'react-router-dom';
import axios from 'axios';
import {isEmpty} from 'config';

class Header extends Component {
    
    constructor(props) {
        super(props);
		this.initialState = {
			isCustomerLoggedin: false,
			navToggleOn: false,
			navClassName: "nav-wrapper sticky-header fixed-topno"
		}
		this.state 					= this.initialState;
		if(!isEmpty(localStorage.getItem('auth'))){
           this.state.isCustomerLoggedin = true;
        }
    }
	
	enableMobileNav = () => {
		(this.state.navToggleOn)?
		this.setState({
			"navToggleOn" : false,
			"navClassName" : "nav-wrapper sticky-header fixed-topno"
		}):
		this.setState({
			"navToggleOn" : true,
			"navClassName" : this.state.navClassName+" navtoggledjs"
		});
	}
	
    render() {
        return (
				<header className="main-header position-relative">
					<div className="header-thin hidden-mobile">
						<div className="container">
							<div className="row">
								<div className="col-12 col-md-12 ">
									<div className="header-address text-center text-md-center text-lg-right">
										<ul className="list-unstyled text-white">
											<li className="list-inline-item"> <span className="opentime">
											<i className="material-icons">access_time</i></span> <span> Every Saturday and Sunday 9am to 5pm </span>
											</li>
										
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="navbarjs" className={this.state.navClassName}>
						<div className="logo-bg"></div>
						<div className="container">
							<div className="row">
								<div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 flex-logo">
									<Link to="/" className="navbar-brand"> <img src="/assets/images/logo-w.png" width="345" alt="WeeklyAutoMarket"/></Link>
								</div>
								<div className="col-12 showmobile header-thin-mobile px-0">
									<div className="header-thin">
										<div className="container">
											<div className="row">
												<div className="col-12 col-md-12 ">
													<div className="header-address text-center text-md-center text-lg-right">
														<ul className="list-unstyled text-white">
															<li className="list-inline-item"> <span className="opentime">
															<i className="material-icons">access_time</i></span> <span> Every Saturday and Sunday 9am to 5pm </span>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-6 col-md-8 col-lg-8 flex-nav">
									<div className="nav-container">
										<nav className="navbar navbar-expand-lg navbar-dark nav-container">
											<button type="button" className={(this.state.navToggleOn)?"navbar-toggler toggler-toggled":"navbar-toggler"} data-toggle="collapse" onClick={this.enableMobileNav} data-target="#navbarCollapse">
													<span className="menu-label"> Menu </span>
													 <span className="navbar-toggler-icon">
														 <span className="icon-bar top-bar"></span>
														 <span className="icon-bar middle-bar"></span>
														 <span className="icon-bar bottom-bar"></span>   
													 </span>
											</button>
											<div className={(this.state.navToggleOn)?"collapse navbar-collapse mainnav show":"collapse navbar-collapse mainnav"} id="navbarCollapse">
												<ul className="navbar-nav">
													{ this.props.Content.map((data, i) => <SideBarMenu enableMobileNav={this.enableMobileNav} {...this.props} key={i} Values={data} />)}
													{ (this.state.isCustomerLoggedin)?(<li className="nvbar-item">
														<Link to="/customer/profile" className="nav-item nav-link"> My Account</Link>
												</li>) : (<li className={(this.props.location.pathname === "/customer/login")? "nvbar-item activeli":"nvbar-item"}>
														<Link to="/customer/login" className="nav-item nav-link"> Log In</Link>
													</li>) }
												</ul>
												<div className="fixed-bottom fixed-nav-logo showmobile">
													<Link to="/"><img src="/assets/images/wam-W.png" width="120" alt="Weekly Auto Market"/></Link>
												</div>
											</div>
										</nav>
									</div>
								</div>
								<div className="clearfix"></div>
							</div>
						</div>
					</div>
				</header>
                );
    }
}
export default Header;

class SideBarMenu extends Component {
	getNavLinkClass = path => {
		return this.props.location.pathname === path
		  ? "nvbar-item activeli"
		  : "nvbar-item";
	  };
    render() {
        return(
                ((typeof this.props.Values.component !== "undefined") && this.props.Values.display) ?
                (<li className={this.getNavLinkClass(this.props.Values.path)}>
                    <NavLink to={this.props.Values.path} className="nav-item nav-link" onClick={this.props.enableMobileNav} exact activeClassName="active">
						{this.props.Values.name}
                    </NavLink>
                </li>) : (null)
                )
    }
}