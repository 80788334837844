import React, { Component, Suspense } from 'react';
import {Link} from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import {customerRoutes} from 'routes';
import {isEmpty} from 'config';
import NoMatchPage from 'views/pages/NoMatchPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Route, Switch } from 'react-router-dom';
toast.configure({"className":"toast-notify"});

export default class CustomerLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        }
        if (isEmpty(localStorage.getItem('auth'))) {
            localStorage.setItem('preURL', window.location.pathname + window.location.search);
            this.props.history.push('/customer/login');
        }else{
            if(window.location.pathname == "/customer" || window.location.pathname == "/customer/" ) this.props.history.push('/customer/profile')
        }
    } 

    render() {
        return(
            <div className="page-content-wrapper">
				<Header Content={customerRoutes} {...this.props}/>
				<Suspense fallback={this.props.startLoader()}>
					<Switch>
						{customerRoutes.map((route, idx) => {
							return (typeof route.component !== "undefined" ? (
								<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...this.props} {...props} />)} />
								) : (null))
						} ) }
						<Route component={NoMatchPage} />
					</Switch>
				</Suspense>
				<Footer props = {this.props} />
            </div>
        );
    }
}